import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import { parseResource } from '@/@core/utils/utils'
import { NAV_ITEM_ID } from '@/constants'
import dashboardIcon from '@/assets/images/icons/dashboardIcon.svg'
import BadgeIcon from '@/assets/images/icons/BadgeIcon.svg'
import AnalyticsIcon from '@/assets/images/icons/analyticsIcon.svg'
import leadAiIcon from '@/assets/images/icons/lead.svg'
import BulbIcon from '@/assets/images/icons/bulb-icon.svg'
import { routePaths } from '@/router/routes/apps'

export default function useVerticalNavMenu(props) {
  // ------------------------------------------------
  // isVerticalMenuCollapsed
  // ------------------------------------------------
  const isVerticalMenuCollapsed = computed({
    get: () => store.state.verticalMenu.isVerticalMenuCollapsed,
    set: val => {
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', val)
    },
  })

  // ------------------------------------------------
  // collapseTogglerIcon
  // ------------------------------------------------
  const collapseTogglerIcon = computed(() => {
    if (props.isVerticalMenuActive) {
      return isVerticalMenuCollapsed.value ? 'unpinned' : 'pinned'
    }
    return 'close'
  })

  const isMouseHovered = ref(false)

  const updateMouseHovered = val => {
    isMouseHovered.value = val
  }

  const toggleCollapsed = () => {
    isVerticalMenuCollapsed.value = !isVerticalMenuCollapsed.value
  }

  const NAV_MENU_ITEM = {
   DASHBOARD: (isGuest = false) => ({
      title: 'Dashboard',
      tagId: 'intro_dashboard',
      img: dashboardIcon,
      route: 'dashboard',
      resource: parseResource('Dashboard'),
      action: 'read',
      disabled: isGuest,
      navItemId: NAV_ITEM_ID.DASHBOARD,
    }),
    PROFILE: (isGuest = false) => ({
      tagId: 'intro_profile',
      title: 'Profile',
      route: 'profile',
      icon: 'SettingsIcon',
      resource: parseResource('Profile'),
      action: 'read',
      disabled: isGuest,
      navItemId: NAV_ITEM_ID.PROFILE,
    }),
    WISHLIST: (isGuest = false) => ({
      tagId: 'intro_whish_list',
      title: 'Wishlist',
      route: routePaths.wishlist,
      icon: 'HeartIcon',
      resource: parseResource('Retailer'),
      disabled: isGuest,
      navItemId: NAV_ITEM_ID.WISHLIST,
    }),
    SELECTION: (isGuest = false) => ({
      title: 'Selections',
      route: routePaths.selection,
      img: BadgeIcon,
      resource: parseResource('Brand'),
      disabled: isGuest,
      navItemId: NAV_ITEM_ID.SELECTIONS,
    }),
    SALES_ANALAYTICS: (isGuest = false) => ({
      title: 'Sales Insights',
      route: routePaths.salesAnalytics,
      img: BulbIcon,
      resource: parseResource('Brand'),
      disabled: isGuest,
      navItemId: NAV_ITEM_ID.SALES_ANALYTICS,
    }),
    CHECKOUT: (isGuest = false) => ({
      tagId: 'intro_checkout',
      title: 'Checkout',
      route: 'checkout',
      icon: 'ShoppingCartIcon',
      resource: parseResource('Retailer'),
      disabled: isGuest,
      navItemId: NAV_ITEM_ID.CHECKOUT,
    }),
    COLLECTION: {
      tagId: 'intro_collections',
      title: 'Collections',
      route: 'collections',
      icon: 'ImageIcon',
      resource: 'Brand',
      navItemId: NAV_ITEM_ID.COLLECTIONS,
    },
    CONNECTIONS: (title = '', img = '', isGuest = false) => ({
      title,
      img,
      route: 'connections',
      resource: parseResource('Wallet'),
      action: 'read',
      disabled: isGuest,
      navItemId: NAV_ITEM_ID.CONNECTIONS,
    }),
    ORDERS: (isGuest = false) => ({
      tagId: 'intro_orders',
      title: 'Orders',
      route: 'orders',
      icon: 'StarIcon',
      resource: parseResource('Order'),
      action: 'read',
      disabled: isGuest,
      navItemId: NAV_ITEM_ID.ORDERS,
    }),
    SHIPMENTS: (isGuest = false) => ({
      tagId: 'intro_shipments',
      title: 'Shipments',
      route: 'shipments',
      icon: 'TruckIcon',
      resource: parseResource('Shipment'),
      action: 'read',
      disabled: isGuest,
      navItemId: NAV_ITEM_ID.SHIPMENTS,
    }),
    DOWNLOADS: (isGuest = false) => ({
      tagId: 'intro_downloads',
      title: 'Downloads',
      route: routePaths.downloads,
      icon: 'DownloadIcon',
      resource: parseResource('Profile'),
      action: 'read',
      disabled: isGuest,
      navItemId: NAV_ITEM_ID.DOWNLOADS,
    }),
    ANALYTICS: {
      title: 'Analytics (beta)',
      route: 'analytics',
      img: AnalyticsIcon,
      resource: 'Brand',
      action: 'read',
      navItemId: NAV_ITEM_ID.ANALYTICS,
    },
    LEAD_AI: {
      title: 'Leads AI',
      route: 'leads-gen-ai',
      img: leadAiIcon,
      resource: 'Brand',
      action: 'read',
      navItemId: NAV_ITEM_ID.LEAD_AI,
    },
  }

  return {
    isMouseHovered,
    isVerticalMenuCollapsed,
    collapseTogglerIcon,
    toggleCollapsed,
    updateMouseHovered,
    NAV_MENU_ITEM,
  }
}
