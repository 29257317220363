<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    :id="`tootltip_${item.route}`"
    class="nav-item kp-nav-item"
    :class="{
      active: isActive,
      'intro-view-all-brands': isActive,
      'help-class': item.title === 'Help'
    }"
    @click="trackClicks"
  >
    <b-tooltip
      v-if="isTooltipEnabled"
      :target="`tootltip_${item.route}`"
      placement="bottom"
    >
      You can't access the marketplace
    </b-tooltip>
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
      :disabled="item.disabled"
      :class="{
        'link-disabled': item.disabled
      }"
    >
      <!-- Main Nav Image -->
      <b-img
        v-if="item.img"
        :src="computedImage(item)"
        :alt="item.tag"
        width="20px"
        height="20px"
        class="mr-1"
        :class="{'rounded-circle': isChild}"
      />
      <!-- Main Nav Icon -->
      <feather-icon
        v-if="item.icon"
        :icon="item.icon || ''"
      />
      <span
        v-if="!item.img && !item.icon"
        class="empty-img-margin"
      />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <!-- Notify Icon -->
      <div
        v-if="showRedDotIndicator"
        class="spinner-grow text-danger ml-1"
        :class="{ 'is-collapsed': !isExpanded }"
      />
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import {
 BLink, BImg, BTooltip,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import constants from '@/constants'
import analytics from '@/@core/utils/analytics'
import UserRoleMixinVue from '@/views/apps/UserRoleMixin.vue'
import { mapState } from 'vuex'
import { routePaths } from '@/router/routes/apps'
import { enrichTrackName } from '@/@core/utils/utils'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BImg,
    BTooltip,
  },
  mixins: [mixinVerticalNavMenuLink, UserRoleMixinVue],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isTooltipEnabled() {
      return this.item.disabled && ['premiumbrands', 'explore'].includes(this.item.route)
    },
    ...mapState({
      wishlistsNavIndicator: state => state.notification.wishlistsNavIndicator,
      downloadsNavIndicator: state => state.notification.downloadsNavIndicator,
      SalesAnalyticsNavIndicator: state => state.notification.SalesAnalyticsNavIndicator,
    }),
    isNotifyWishlistTab() {
      const { wishlist, selection } = routePaths
      const wishlistTabs = [wishlist, selection]
      return this.wishlistsNavIndicator && wishlistTabs.includes(this.item?.route)
    },
    isNotifyDownloadsTab() {
      const { downloads } = routePaths
      return this.downloadsNavIndicator && this.item?.route === downloads
    },
    isNotifySalesAnalyticesTab() {
      const { salesAnalytics } = routePaths
      return this.SalesAnalyticsNavIndicator && this.item?.route === salesAnalytics
    },
    showRedDotIndicator() {
      return this.isNotifyWishlistTab || this.isNotifyDownloadsTab || this.isNotifySalesAnalyticesTab
    },
  },
  methods: {
    computedImage(item) {
      if (this.$route.name === item.route) {
        return item.activeImg || item.img
      }
      return item.img
    },
    trackClicks() {
      analytics.track(enrichTrackName(constants.TRACKS.ACTIONS.CLICKS_ON_SIDEBAR_ICON, {
        user: this.isBrand ? 'Brand' : 'Retailer',
        navItemName: this.item.title,
      }))
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item,
    )
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
.empty-img-margin {
  margin-left: 31px;
}
.feather-slash {
  color: $danger;
  height: 20px !important;
  width: 20px !important;
  margin-right: 1rem !important;
}
.link-disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.kp-nav-item {
  margin: 0.5rem 0;
}

.spinner-grow  {
  width: 0.8rem;
  height: 0.8rem;

  &.is-collapsed {
    position: absolute;
    top: 15%;
    right: 35%;
  }
}

</style>
